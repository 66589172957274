import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import headerBg from '../images/header-bg.png';

const Header = ({ siteTitle }) => (
  <header className="header">
    {/* <img className="header__bg" src={headerBg} /> */}
      {/* <h1>
        <Link to="/">{siteTitle}</Link>
      </h1> */}
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `Accelerate C-Suite | Adrienne Stevens`,
}

export default Header
