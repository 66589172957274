/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import { Helmet } from "react-helmet"
import "../styles/main.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <title>Accelerate C-Suite | Adrienne Stevens</title>
        <html lang="en" />
        <meta
          name="description"
          content="
        With more than 25 years of corporate, coaching and management experience, Adrienne Stevens will help you to achieve your personal and professional goals to help you become the best you can be."
        />
      </Helmet>

      <Header siteTitle="Accelerate C-Suite | Adrienne Stevens" />
      <div>
        <main className="app">{children}</main>
        {/* <footer>
          © {new Date().getFullYear()} Accelerate C-Suite Coaching
        </footer> */}
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
